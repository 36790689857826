<template>
  <v-container class="max-container">
    <!-- <div>one channel</div> -->
    <v-card flat class="pa-1 sub-card" color="black">
      <v-card-text>
        <div class="layout column align-center" @click="goSettings">
          <div class="v-card__title justify-center display-1 mb-2">
            <v-img :src="profile_picture_symbol" max-height="60" max-width="60" />
          </div>
          <div class="justify-center display-1 mb-1 user-card" style="color:white;">
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <!-- <v-icon>mdi-account-circle </v-icon> -->
                  <v-img :src="userInfo.photo_url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="userInfo.nickname" />
                  <v-list-item-subtitle>Email: {{ userInfo.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="goSettings">
                    <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card-text>
      <div class="install-banner" @click="toReferral">
        <v-banner
          single-line
          dark
          style="text-align:start; color:white"
        >
          <div class="referral-coin">
            <img
              width="40"
              src="@/assets/coin.png"
              alt="coin"
            >
          </div>
          <div class="referral-desc">
            <div class="first-line">Invite friends to earn points!</div>
            <div class="second-line">Invite Friends <v-icon color="primary">mdi-chevron-right</v-icon></div>
          </div>

          <!-- <template v-slot:actions>
            <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
          </template> -->
        </v-banner>
      </div>
      <!-- <div class="install-banner" @click="installApp">
        <v-banner
          single-line
          dark
          style="text-align:start; color:white"
        >
          <v-icon
            slot="icon"
            color="purple"
            size="28"
          >
            mdi-download
          </v-icon>
          Install One Channel App
          <template v-slot:actions>
            <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
          </template>
        </v-banner>
      </div> -->
      <div class="sub-box">
        <div class="subscribed-box">
          <div class="title-box">My Channel:</div>
          <div class="channel-box">
            <div v-show="SubscribedList.length===0" class="no-data-text">
              You don't have any subscriptions
            </div>
            <v-row>
              <v-col v-for="(item, index) in SubscribedList" :key="index" xs="6" sm="4" md="4" lg="4" xl="4" align-self="center">
                <div class="image-item" @click="openApp(item)">
                  <v-img :src="item.channel_photo" />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="available-box">
          <div class="title-box">Available channels:</div>
          <div class="channel-box">
            <div v-show="AvailableList.length===0" class="no-data-text">
              No channels available for subscription
            </div>
            <v-row>
              <v-col v-for="item in AvailableList" :key="item.platform_name" xs="6" sm="4" md="4" lg="4" xl="4" align-self="center">
                <div class="image-item" @click="changeSub">
                  <v-img :src="item.channel_photo" />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <!-- <v-footer
        padless

        class="font-weight-medium"
      >
        <v-col
          class="align-center justify-center"
        >

        </v-col>
      </v-footer> -->
    </v-card>
    <div class="button-box">
      <v-btn block depressed large class="tile text-capitalize manage-sub" :disabled="changeSubLoading" :loading="changeSubLoading" @click="changeSub">Manage Channels</v-btn>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-dialog
      v-model="downloadAppDialog"
      max-width="290"
      min-height="180"
      style="background: #1E1E1E!important;"
    >
      <v-card
        dark
        color="black"
        style="background: #1E1E1E!important;"
      >
        <v-card-title class="display-1 mb-2">
          <div class="download-app-title">Download the app to use</div>
        </v-card-title>
        <div class="download-box">
          <v-btn
            class="ma-2 text-none btn-download-ios"
            color="purple"
            dark
            width="160px"
            @click="downloadApp('ios')"
          >
            iOS Download
            <v-icon
              dark
              right
            >
              mdi-apple
            </v-icon>
          </v-btn>

          <v-btn
            class="ma-2 text-capitalize btn-download-android"
            color="purple"
            dark
            width="160px"
            @click="downloadApp('android')"
          >
            Android Download
            <v-icon
              dark
              right
            >
              mdi-android
            </v-icon>
          </v-btn>
        </div>
        <div class="install-text-box">
          <v-btn
            medium
            class="text-none install-text-button"
            dark
            width="160px"
            text
            @click="hideDownload"
          >
            I have installed
          </v-btn>
          <!-- <div style="height:10px;backgound:transparent" /> -->
        </div>
      </v-card>
    </v-dialog>
    <c-snack-bar ref="csnackbar" />
    <get-account-dialog ref="getAccountDialog" :platform="selectedPlatform" :account="account" :password="password" />
  </v-container>
</template>

<script>

import { getChannel, getAccount } from '@/api/user'
// eslint-disable-next-line
import { checkDefaultPayment } from '@/api/user'

import profile_picture_symbol from '@/assets/profile_picture_symbol.png'

import { isAuth } from '@/util/auth' // get token from cookie

import getAccountDialog from '@/views/auth/components/getAccount'
import CSnackBar from './components/snackbar'

// import Cookies from 'js-cookie'

export default {
  name: 'Subscription',
  components: {
    getAccountDialog,
    CSnackBar
  },
  data: () => ({
    changeSubLoading: false,
    downloadAppDialog: false,
    overlay: false,
    profile_picture_symbol: profile_picture_symbol,
    channelList: [],
    ignorePlatform: 'prime video',
    userInfo: {},
    selectedPlatform: '',
    account: '',
    password: ''
  }),
  computed: {
    SubscribedList() {
      return this.channelList.filter(item => item.check)
    },
    AvailableList() {
      return this.channelList.filter(item => !item.check && item.status === 0)
    }
  },
  created() {
    if (isAuth()) {
      this.get_channel()
      this.userInfo = this.$store.state.user.userInfo.user
      const sub = this.$store.state.user.userInfo.subscription
      const payGosub = this.$store.state.user.userInfo.manual_sub_platforms
      if (!sub && !payGosub) {
        this.$router.push({ name: 'subscription' })
      }
      const hideDownloadAppPopup = window.localStorage.getItem('hideDownloadAppPopup')
      this.downloadAppDialog = !hideDownloadAppPopup
    }
  },
  methods: {
    get_channel() {
      this.overlay = true
      var sub = this.$store.state.user.userInfo.subscription
      var manual_sub_platforms = this.$store.state.user.userInfo.manual_sub_platforms
      var current_subs = manual_sub_platforms.split(';')
      if (sub) {
        current_subs = current_subs.concat(sub.platforms.split(';'))
      }
      getChannel().then(res => {
        this.channelList = res.data.map(item => {
          item.check = current_subs.findIndex(s => item.platform_name === s) > -1
          return item
        })
      }, () => {

      }).finally(() => {
        this.overlay = false
      })
    },
    async changeSub() {
      try {
        // this.changeSubLoading = true
        // const default_card = await checkDefaultPayment()
        // const has_card = default_card.code === 0
        // if (!has_card) {
        //   this.$router.push({ name: 'payment' })
        //   return
        // }
        this.$router.push({ path: '/auth/subscription' })
      } catch (error) {
        this.changeSubLoading = false
      }
    },
    goSettings() {
      this.$router.push({ name: 'settings' })
    },
    downloadApp(op) {
      // var utm = { 'utm_source': Cookies.get('utm_source') || 'website', 'utm_medium': Cookies.get('utm_medium'), 'utm_campaign': Cookies.get('utm_campaign') || 'web_app' }
      // var utm_query_str = Object.keys(utm).map(function(n, i) {
      //   return `${n}=${utm[n]}`
      // }).join('&')
      // var download_url = 'https://onechannel.app.link?' + utm_query_str
      var download_url = 'https://play.google.com/store/apps/details?id=com.onechannel.app'
      if (op === 'ios') {
        download_url = 'https://apps.apple.com/us/app/one-channel-plus/id1600268190'
      }
      window.open(download_url, '_blank')
    },
    installApp() {
      this.$router.push({ name: 'InstallApp' })
    },
    hideDownload() {
      window.localStorage.setItem('hideDownloadAppPopup', true)
      this.downloadAppDialog = false
    },
    openApp(item) {
      this.getAccount(item)
    },
    toReferral() {
      this.$router.push({ name: 'Referral' })
    },
    getAccount(item) {
      this.overlay = true
      getAccount({ 'identifier': item.platform_name }).then(res => {
        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }
        this.showNackBar('success', 'Success')
        this.account = res.data.account
        this.password = res.data.password
        this.selectedPlatform = item.platform_name.charAt(0).toUpperCase() + item.platform_name.slice(1)
        if (['espn', 'hbo', 'paramount plus', 'disney plus'].includes(item.platform_name)) {
          const channel_map = { 'espn': 'ESPN+', 'hbo': 'HBOMAX', 'paramount plus': 'Paramount+', 'disney plus': 'Disney+' }
          this.selectedPlatform = channel_map[item.platform_name]
        }
        this.profile_name = res.data.pin_name
        this.$refs.getAccountDialog.dialog = true
      }, () => {
        this.showNackBar('error', 'System error, please try again later!')
      }).finally(() => {
        this.overlay = false
      })
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text{
    font-size: 20px;
    font-weight: 320;
}

.text--primary{
    font-size: 18px;
}

.total-price-box{
    color: white;
    text-align: center;
    font-size:medium;
    font-weight: bold;
    padding: 16px;
    .total-price{
        font-size:1.8em;
        font-weight: bold;
        // padding-top: 3px;
        color: #8C9EFF;
    }
}

.container .container--fluid .fill-height{
  background: white!important;
}

.image-item{
  border-radius: 9px;
  position: relative;
  height:90px;
  width:148px;
  .v-image{
    height:78px;
    border-radius: 5px;
  }
  .check-icon-box{
    position: relative;
    top: -82px;
    left: -1px;
    width: 20px;
    background: #E040FB;
    border-radius: 0 0 8px 0;
  }
}

.box-border-radius{
  border: 6px solid #E040FB;
}

.button-box{
  // margin-top: 30px;
  .v-btn{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.user-card{
    width: 100%;
    .v-list{
        border-radius: 8px!important;
        background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
        color:white
    }
    .v-list-item{
        height: 64px!important;
        .v-list-item__title{
            letter-spacing: 0.08em;
            color: white;
            font-weight: bold;
        }
        .v-list-item__subtitle{
            padding-top: 2px;
            font-size:12px;
            color: #dddddd;
        }
    }
    .v-icon{
        font-size: 3em;
        color: black;
    }
    .v-image{
        width: 60px;
        height: 60px;
    }
}

.v-card__subtitle, .v-card__text, .v-card__title{
        padding: 0!important;
}

.sub-box{
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 56vh;
    .title-box{
        padding-top: 10px;
        padding-left: 10px;
        letter-spacing: 0.05em;
        color:white;
        font-weight: bold;
        font-size: 15px;
    }
}

.no-data-text{
    font-weight: bold;
    color: rgb(100, 87, 87);
    text-align: center;
    padding: 16px;
    height: 60px;
}

.v-footer{
    border: unset!important;
}

.sub-card{
    position: relative;
}

.theme--light.v-footer{
    background-color: transparent!important;
}

.download-app-title{
  padding: 10px;
  padding-top: 20px;
}

.download-box{
  text-align: center;
}

.v-banner__icon{
  margin-right: 0px!important;
}

.install-banner{
  cursor: pointer;
  .theme--dark.v-sheet{
    background-color: black!important;
  }
  :hover{
    background-color: #1E1E1E;
  }
}

.install-text-box{
  text-align: center;
  padding-bottom: 10px;
}

.referral-coin{
  float: left;
}

.referral-desc{
  margin-left: 60px;
  .first-line{
      font-weight: bold;
    }

  .second-line{
    color: #E040FB;
  }
}
</style>
